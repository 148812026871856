import React from "react"

const Work = () => {
  return (
    <div className="container work p-6">
      <div>
        <p>
          Jesteśmy polską firmą, jednym z czołowych dystrybutorów tkanin do
          rolet wewnętrznych. W naturę naszej firmy wpisany jest rozwój i
          nastawienie na osiągnięcie sukcesu. Jeśli jesteś osobą, która chce się
          z nami rozwijać, szukasz wyzwań i ciekawych zadań nasza oferta jest
          właśnie dla Ciebie!
          <br />
          <br />W związku z dynamicznym rozwojem firmy poszukujemy osoby na
          stanowisko:
        </p>
      </div>

      <div>
        <div className="is-size-3 mt-4">Key Account Specialist</div>
        <div className="is-size-6 mt-4">
          Miejsce pracy: <b>Bydgoszcz</b>
        </div>
      </div>

      <div className="mt-4">
        <p>Opis stanowiska:</p>
        <ul className="mt-4">
          <li>
            Budowanie, utrzymanie i rozwój relacji z aktualnymi kontrahentami
            firmy
          </li>
          <li>Pozyskiwanie nowych klientów</li>
          <li>Realizacja założonych celów sprzedażowych i jakościowych</li>
          <li>Raportowanie wyników sprzedaży</li>
          <li>Negocjacje handlowe</li>
          <li>Kreowanie dobrego wizerunku firmy</li>
        </ul>
      </div>

      <div className="mt-4">
        <p>Jeśli posiadasz:</p>
        <ul className="mt-4">
          <li>Wykształcenie min. średnie</li>
          <li>Min. 2 letnie doświadczenie w pracy handlowca</li>
          <li>własna działalność gospodarcza</li>
          <li>Czynne prawo jazdy kat. B</li>
          <li>Umiejętności sprzedażowe i negocjacyjne</li>
          <li>Wysoki poziom kultury osobistej</li>
          <li>
            Mile widziana znajomość lokalnego rynku artykułów metalowych,
            narzędzi ręcznych lub materiałów budowlanych
          </li>
          <li>Doskonała organizacja pracy</li>
          <li>Nastawienie na wyniki</li>
          <li>Samodzielność</li>
          <li>Zaangażowanie</li>
          <li>Inicjatywa i aktywnoś</li>
        </ul>
      </div>

      <div className="mt-4">
        <p>Oferujemy Ci: </p>
        <ul className="mt-4">
          <li>
            Pracę w polskiej firmie (niekorporacyjne zwyczaje i procedury)
          </li>
          <li>Przyjazne środowisko pracy</li>
          <li>Premię od sprzedaży</li>
          <li>Zatrudnienie w branży, która ma dobre perspektywy</li>
          <li>Miłą i przyjazną atmosferę w pracy</li>
          <li>Możliwość uczestnictwa w tworzeniu firmy</li>
        </ul>
      </div>
    </div>
  )
}

export { Work }
